import * as React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import theme from '../../components/atoms/theme'
import Layout from '../../components/layout'
import Header from '../../components/molecules/header'
import Breadcrumbs from '../../components/molecules/breadcrumbs'
import Button from '../../components/molecules/button'
import HealthCheckIcon from '../../components/atoms/graphics/health-check-icon'
import DigitalPortalIcon from '../../components/atoms/graphics/digital-portal-icon'
import RegisterIcon from '../../components/atoms/graphics/register-icon'
import ArrowRightIcon from '../../components/atoms/graphics/arrow-right'

interface StyleProps {
  backgroundColour?: string
}

const Description = styled.h3`
  max-width: 80%;
  text-align: left;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: left;
    max-width: 100%;
    margin-bottom: 2.5rem;
    font-size: 1.9rem;
  }
`
const BodyContainer = styled.div`
  padding-top: 5.5rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding-top: 0;
  }
`

const SectionContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3rem 0;
  border-top: 1px solid ${theme.colours.gray3};
  ul {
    margin-top: 0;
    p {
      margin: 0;
    }
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    align-items: start;
    padding: 2rem 0 2.5rem;
  }
`

const IconContainer = styled.div<{ backgroundColour: string }>`
  height: 18rem;
  width: 18rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props: StyleProps): string => props.backgroundColour};
  margin-right: 5rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    height: 4rem;
    width: 4rem;
    margin-right: 1.5rem;
    svg {
      height: 2.4rem;
    }
  }
`

const ContentContainer = styled.div`
  width: 66%;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: auto;
  }
`

const ContentHeader = styled.h3`
  color: ${theme.colours.secondary};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 1.9rem;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledButton = styled(Button)`
  padding: 10px 24px;
  height: 4.4rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  min-width: unset;
  margin-top: 3rem;
  span {
    padding-right: 0.5rem;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    justify-content: center;
    margin-top: 1.5rem;
  }
`

interface Props {
  location: {
    pathname: string
  }
}

const BusinessHealthCheck = (props: Props): JSX.Element => {
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const currentPath = get(props, 'location.pathname')
  const allPages = get(props, 'data.allContentfulPage.nodes')
  const currentPage = allPages.find(page => get(page, 'slug') === currentPath)
  const description = currentPage ? currentPage.description.description : null

  const data = get(props, 'data.allContentfulBusinessHealthCheck.nodes')
  const businessHealthCheck = data.slice(0, 3)
  const sectionIcon = [
    { backgroundColour: theme.colours.secondary, icon: <RegisterIcon /> },
    { backgroundColour: theme.colours.primary, icon: <HealthCheckIcon /> },
    { backgroundColour: theme.colours.quaternary, icon: <DigitalPortalIcon /> },
  ]

  return (
    <Layout currentPath={currentPath}>
      <Helmet title={siteTitle} />
      <Header title={currentPage.title}>
        <Breadcrumbs
          crumbs={[
            {
              label: 'Services',
              path: '/services/',
            },
            {
              label: 'Business health check',
              isActive: true,
            },
          ]}
        />
      </Header>
      {description && <Description>{description}</Description>}
      <BodyContainer>
        {businessHealthCheck.map((section, index) => {
          return (
            <SectionContainer key={index}>
              <IconContainer
                backgroundColour={sectionIcon[index].backgroundColour}
              >
                {sectionIcon[index].icon}
              </IconContainer>

              <ContentContainer>
                <ContentHeader>{section.header}</ContentHeader>
                <div>{documentToReactComponents(section.body.json)}</div>
                {section.buttonUrl && (
                  <StyledLink to={section.buttonUrl}>
                    <StyledButton>
                      <span>{section.buttonLabel}</span> <ArrowRightIcon />
                    </StyledButton>
                  </StyledLink>
                )}
              </ContentContainer>
            </SectionContainer>
          )
        })}
      </BodyContainer>
    </Layout>
  )
}

export default BusinessHealthCheck

export const pageQuery = graphql`
  query BusinessHealthCheckQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBusinessHealthCheck(sort: { fields: [position], order: ASC }) {
      nodes {
        position
        header
        body {
          json
        }
        buttonLabel
        buttonUrl
      }
    }
    allContentfulPage {
      nodes {
        title
        slug
        description {
          description
        }
      }
    }
  }
`
