import * as React from 'react'
import theme from '../theme'

interface Props {
  colour?: string
}

const DigitalPortalIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
    >
      <path
        fill={props.colour ? props.colour : theme.colours.white}
        fillRule="evenodd"
        d="M47 61.5c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1H25c-.552 0-1-.448-1-1v-1c0-.552.448-1 1-1h22zM61.5 9c3.314 0 6 2.686 6 6v33c0 1.093-.292 2.118-.803 3.001L68.25 51c2.071 0 3.75 1.679 3.75 3.75 0 2.071-1.679 3.75-3.75 3.75H3.75C1.679 58.5 0 56.821 0 54.75 0 52.679 1.679 51 3.75 51l1.553.001C4.793 50.118 4.5 49.093 4.5 48V15c0-3.314 2.686-6 6-6zm6.75 45H3.75c-.414 0-.75.336-.75.75s.336.75.75.75h64.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75zM61.5 12h-51c-1.657 0-3 1.343-3 3v33c0 1.657 1.343 3 3 3h51c1.657 0 3-1.343 3-3V15c0-1.657-1.343-3-3-3zM37.578 34.5c4.216 0 7.53 3.3 7.53 7.5v3c0 .9-.603 1.5-1.506 1.5-.904 0-1.506-.6-1.506-1.5v-3c0-2.55-1.958-4.5-4.518-4.5H25.53c-2.56 0-4.518 1.95-4.518 4.5v3c0 .9-.602 1.5-1.506 1.5S18 45.9 18 45v-3c0-4.2 3.313-7.5 7.53-7.5h12.048zm9.036 1.35c.15-.75 1.054-1.2 1.807-1.05 3.464.75 5.723 3.75 5.572 7.2v3c0 .9-.602 1.5-1.506 1.5s-1.506-.6-1.506-1.5v-3c0-2.1-1.355-3.9-3.313-4.35-.753-.15-1.205-1.05-1.054-1.8zM31.554 16.5c4.217 0 7.53 3.3 7.53 7.5s-3.313 7.5-7.53 7.5c-4.217 0-7.53-3.3-7.53-7.5s3.313-7.5 7.53-7.5zm9.036 1.35c.3-.9 1.054-1.35 1.807-1.05 4.066.9 6.476 5.1 5.421 9.3-.602 2.7-2.71 4.65-5.421 5.4h-.301c-.753 0-1.356-.45-1.506-1.2-.302-.75.3-1.65 1.054-1.8 1.656-.45 2.861-1.65 3.313-3.3.602-2.55-.904-4.95-3.313-5.55-.753-.15-1.205-1.05-1.054-1.8zm-9.036 1.65c-2.56 0-4.518 1.95-4.518 4.5s1.958 4.5 4.518 4.5c2.56 0 4.518-1.95 4.518-4.5s-1.958-4.5-4.518-4.5z"
      />
    </svg>
  )
}

export default DigitalPortalIcon
