import * as React from 'react'
import theme from '../theme'

interface Props {
  colour?: string
}

const RegisterIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      viewBox="0 0 66 66"
    >
      <path
        fill={props.colour ? props.colour : theme.colours.white}
        fillRule="evenodd"
        d="M23.288 2.28c1.686-1.488 4.26-1.426 5.872.185l.233.22c1.036.925 2.378 1.44 3.774 1.44h1.208c2.278 0 4.125 1.847 4.125 4.125h4.125c3.697 0 6.712 2.918 6.869 6.577l.006.298v44c0 3.697-2.918 6.712-6.577 6.869l-.298.006h-33c-3.697 0-6.712-2.918-6.869-6.577l-.006-.298v-44c0-3.697 2.918-6.712 6.577-6.869l.298-.006h4.125c0-2.278 1.847-4.125 4.125-4.125h1.208c1.503 0 2.944-.597 4.007-1.66zM13.75 11H9.625c-2.197 0-3.993 1.717-4.118 3.883l-.007.242v44c0 2.197 1.717 3.993 3.883 4.118l.242.007h33c2.197 0 3.993-1.717 4.118-3.883l.007-.242v-44c0-2.197-1.717-3.993-3.883-4.118L42.625 11H38.5c0 2.278-1.847 4.125-4.125 4.125h-16.5c-2.278 0-4.125-1.847-4.125-4.125zm44.688 5.5c2.657 0 4.812 2.155 4.812 4.813v29.562c0 .322-.113.633-.319.88l-3.437 4.125c-.55.66-1.563.66-2.113 0l-3.437-4.125c-.206-.247-.319-.558-.319-.88V21.312c0-2.657 2.155-4.812 4.813-4.812zM39.874 52.25c.76 0 1.375.616 1.375 1.375 0 .705-.53 1.286-1.215 1.366l-.16.009h-27.5c-.76 0-1.375-.616-1.375-1.375 0-.705.53-1.286 1.215-1.366l.16-.009h27.5zm18.563-33c-1.14 0-2.063.923-2.063 2.063v29.063l2.063 2.475 2.062-2.477V21.312c0-1.072-.818-1.953-1.864-2.053zM39.874 44c.76 0 1.375.616 1.375 1.375 0 .705-.53 1.286-1.215 1.366l-.16.009h-27.5c-.76 0-1.375-.616-1.375-1.375 0-.705.53-1.286 1.215-1.366l.16-.009h27.5zm0-8.25c.76 0 1.375.616 1.375 1.375 0 .705-.53 1.286-1.215 1.366l-.16.009h-27.5c-.76 0-1.375-.616-1.375-1.375 0-.705.53-1.286 1.215-1.366l.16-.009h27.5zm0-8.25c.76 0 1.375.616 1.375 1.375 0 .705-.53 1.286-1.215 1.366l-.16.009h-27.5c-.76 0-1.375-.616-1.375-1.375 0-.705.53-1.286 1.215-1.366l.16-.009h27.5zM27.081 4.29c-.605-.48-1.487-.44-2.046.12-1.579 1.578-3.72 2.465-5.952 2.465h-1.208c-.76 0-1.375.616-1.375 1.375V11c0 .76.616 1.375 1.375 1.375h16.5c.76 0 1.375-.616 1.375-1.375V8.25c0-.76-.616-1.375-1.375-1.375h-1.208c-2.232 0-4.373-.887-5.952-2.465z"
      />
    </svg>
  )
}

export default RegisterIcon
